.TransactionsView__root {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}

.TransactionsView__header-container {
    width: 100%;
    margin-top: 61px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
}

.TransactionsView__title-container {
    margin-left: 64px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}

.TransactionsView__title {
    font-weight: 600;
    font-size: 32px;
    line-height: 40px;
    color: #161616;
}

.TransactionsView__counter {
    font-weight: 700;
    font-size: 16px;
    line-height: 22px;
    letter-spacing: 0.16px;
    color: #6F6F6F;
}

.TransactionTable__container {
    width: calc(100% - 128px);
    margin-left: 64px;
    margin-top: 38px;
    height: auto;

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;

    border-radius: 16px;
    overflow: hidden;
}

.TransactionTable__header-container {
    width: 100%;
    height: 48px;
    background-color: #fff;
    margin-bottom: 4px;

    display: grid;
    grid-template-columns: 1fr 1fr .7fr 1fr .7fr 1fr 1fr;
}

.TransactionTable__header-label {
    justify-self: flex-start;
    align-self: center;
    
    font-weight: 700;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 0.32px;
    color: #161616;
}

.TransactionTable__items-wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;

    background-color: #fff;
}

.TransactionLine__container {
    width: 100%;
    height: 44px;
    min-height: 44px;
    background-color: #fff;
    cursor: pointer;

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;

    transition: background-color .2s, height .2s;
}

.TransactionLine__container-opened {
    width: 100%;
    height: 111px;
    background-color: #fff;
    cursor: default;
    
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;

    transition: background-color .2s, height .2s;
}

.TransactionLine__container:hover {
    background-color: #EDF5FF;
    transition: background-color .2s, height .2s;
}

.TransactionLine__header-container {
    width: 100%;
    height: 44px;

    display: grid;
    grid-template-columns: 1fr 1fr .7fr 1fr .7fr 1fr 1fr;

    align-items: center;
    justify-content: flex-start;
    position: relative;
}

.TransactionLine__label-id {
    font-weight: 700;
    font-size: 16px;
    line-height: 22px;
    letter-spacing: 0.16px;
    color: #0F62FE;
}

.TransactionLine__label-status {
    font-weight: 700;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 0.32px;
    color: #161616;

    filter: opacity(1);
    transition: filter .2s;
}

.TransactionLine__label-status:hover {
    filter: opacity(0.7);
    transition: filter .2s;
}

.TransactionLine__label {
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 0.32px;
    color: #161616;
}

.TransactionLine__chevron {
    position: absolute;
    right: 16px;

    transform: rotate(0deg);
    transition: transform .3s;
}

.TransactionLine__chevron-rotated {
    position: absolute;
    right: 16px;
    
    transform: rotate(-180deg);
    transition: transform .3s;
}

.TransactionLine__divider {
    width: calc(100% - 32px);
    height: 4px;
    background-color: #F4F4F4;
    margin-left: 32px;
}