.CompanyView__root {
    width: 672px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    border-radius: 16px;
    overflow: hidden;
}

.CompanyView__header-container {
    width: 672px;
    height: 64px;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
    position: relative;
}

.CompanyView__header-title {
    margin-left: 32px;
    font-weight: 600;
    font-size: 20px;
    line-height: 26px;
    color: #161616;
}

.CompanyView__body {
    width: 640px;
    padding-left: 32px;
    align-self: flex-end;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    background-color: #fff;
    margin-top: 4px;
    border-bottom-left-radius: 16px;
    border-bottom-right-radius: 16px;
}

.CompanyView__store-header-plus {
    width: 16px;
    object-fit: contain;
    position: absolute;
    top: 25px;
    right: 32px;
    cursor: pointer;
    filter: opacity(1);
    transition: filter .3s;
}

.CompanyView__store-header-plus:hover {
    filter: opacity(0.7);
    transition: filter .3s;
}