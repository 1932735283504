.MainPage__container {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}

.MainPage__greeting {
    font-weight: 600;
    font-size: 28px;
    line-height: 36px;
    color: #161616;

    margin-left: 64px;
    margin-top: 46px;
}

.MainPage__sub-greeting {
    font-weight: 700;
    font-size: 16px;
    line-height: 22px;
    letter-spacing: 0.16px;
    color: #525252;

    margin-left: 64px;
    margin-top: 9px;
    margin-bottom: 8px;
}

.MainPage__advice {
    font-weight: 700;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 0.32px;
    color: #161616;

    align-self: flex-end;
    margin-top: 16px;
    margin-right: 64px;
    margin-bottom: 32px;
}

.MainPage__advice-action {
    color: #0F62FE;
    cursor: pointer;

    filter: opacity(1);
    transition: filter .2s;
}

.MainPage__advice-action:hover {
    filter: opacity(0.7);
    transition: filter .2s;
}

.MainPage__pages-control {
    align-self: flex-end;
    margin-right: 64px;
    margin-top: 8px;

    height: 48px;
    width: 140px;
    border-radius: 16px;
    background-color: #fff;

    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
}

.MainPage__pages-label {
    align-self: flex-end;
    margin-right: 64px;
    margin-top: 4px;

    color: #161616;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 0.32px;
}