.Buttons__blue-button {
    background-color: #0F62FE;
    height: 32px;
    border-width: 0;
    border-radius: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    position: relative;
    z-index: 1;
    transition: filter .3s;
}

.Buttons__blue-button-disabled {
    background-color: #0F62FE;
    height: 32px;
    border-width: 0;
    border-radius: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    position: relative;
    z-index: 1;
    transition: filter .3s;
    filter:opacity(0.3);
}

.Buttons__blue-button:hover {
    filter:opacity(0.7);
    transition: filter .3s;
}

.Buttons__blue-button-text {
    color: #fff;
    font-size: 14px;
    font-weight: 400;
    font-family: 'Lato'
}

.Buttons__grey-button {
    background-color: #F4F4F4;
    height: 32px;
    border-width: 0;
    border-radius: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: filter .3s;
    position: relative;
    z-index: 1;
}

.Buttons__grey-button:hover {
    filter:opacity(0.7);
    transition: filter .3s;
}

.Buttons__grey-button-text {
    color: #161616;
    font-size: 14px;
    font-weight: 400;
    font-family: 'Lato'
}

.Buttons__yellow-button {
    background-color: #F1C21B;
    height: 32px;
    border-width: 0;
    border-radius: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    position: relative;
    z-index: 1;
    transition: filter .3s;
}

.Buttons__yellow-button:hover {
    filter:opacity(0.7);
    transition: filter .3s;
}

.Buttons__yellow-button-text {
    color: #161616;
    font-size: 14px;
    font-weight: 700;
    font-family: 'Lato'
}