.FiltersView__root {
    width: 100%;
    z-index: 1;
    position: relative;
}

.FiltersView__header-container {
    width: calc(100% - 128px);
    height: 64px;

    border-radius: 16px;

    display: grid;
    grid-template-columns: 2fr 2fr 2fr 3fr;
    grid-gap: 4px;

    position: relative;
    margin-left: 64px;
    margin-bottom: 16px;
}

.FiltersView__header-item {
    background-color: #fff;
    height: 100%;
    width: 100%;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;

    position: relative;
}

.FiltersView__header-item-label {
    margin-left: 16px;
    margin-bottom: 1px;

    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.32px;
    color: #525252;
}

.FiltersView__header-item-input-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;

    cursor: pointer;
}

.FiltersView__header-item-input-placeholder {
    margin-left: 16px;

    font-weight: 700;
    font-size: 16px;
    line-height: 22px;
    letter-spacing: 0.16px;
    color: #A8A8A8;
}

.FiltersView__header-item-input-value {
    margin-left: 16px;
    margin-right: 12px;

    font-weight: 700;
    font-size: 16px;
    line-height: 22px;
    letter-spacing: 0.16px;
    color: #161616;
}

.FiltersView__header-item-input-clear {
    width: 14px;
    height: 14px;
    object-fit: contain;
}

.FiltersView__header-item-search {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    background-color: #fff;
    border-bottom-right-radius: 16px;
    border-top-right-radius: 16px;

    position: relative;
}

.FiltersView__header-item-search-input {
    border: none;
    outline: none;

    width: 100%;
    margin-right: 16px;
    margin-left: 32px;

    font-size: 14px;
    line-height: 18px;
    letter-spacing: 0.32px;
}

.FiltersView__search-items-container {
    position: absolute;
    left: 0;
    top: 72px;

    width: 100%;
    height: auto;
    max-height: 200px;

    overflow-x: hidden;
    overflow-y: auto;
    border-radius: 16px;
    box-shadow: 0px 1px 5px rgba(150, 150, 150, 0.5);
    z-index: 5;
}

.FiltersView__search-items-wrapper {
    width: 100%;
    height: auto;

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    background-color: #fff;
}

.FiltersView__search-item-container {
    height: 40px;
    width: 100%;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    cursor: pointer;
    background-color: #EDF5FF00;
    transition: background-color .2s;
}

.FiltersView__search-item-container:hover {
    background-color: #EDF5FF;
    transition: background-color .2s;
}

.FiltersView__search-item-label {
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 0.16px;
    color: #525252;
    margin-left: 16px;
}

.FiltersView__body-wrapper {
    width: calc(100% - 128px);
    margin-left: 64px;
}

.FiltersView__body-actions-container {
    width: 100%;
    height: 48px;
    border-radius: 16px;
    background-color: #fff;
    margin-bottom: 16px;

    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.FiltersView__body-title {
    margin-left: 16px;

    font-weight: 700;
    font-size: 16px;
    line-height: 22px;
    letter-spacing: 0.16px;
    color: #525252;
}

.FiltersView__body-actions-block {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
}

.FiltersView__body-action-button {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-left: 16px;

    cursor: pointer;
    filter: opacity(1);
    transition: filter .2s;
}

.FiltersView__body-action-button:hover {
    filter: opacity(0.7);
    transition: filter .2s;
}

.FiltersView__body-action-button-label {
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.32px;
    color: #525252;
    margin-right: 6px;
}

.FiltersView__body-action-button-icon {
    width: 12px;
    height: 12px;
    object-fit: contain;
}

.FiltersView__body-chevron {
    transform: rotate(0deg);
    transition: transform .3s;
}

.FiltersView__body-chevron-reversed {
    transform: rotate(-180deg);
    transition: transform .3s;
}

.FiltersView__indexes-wrapper {
    width: calc(100% - 160px);
    margin-left: 80px;
    margin-bottom: 32px;
    height: auto;

    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-auto-rows: 80px;
    grid-gap: 16px;
}

.FiltersView__index-container {
    width: 100%;
    height: 100%;
    border-radius: 16px;
    background-color: #fff;

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;

    position: relative;
}

.FiltersView__index-label {
    margin-left: 16px;
    margin-top: 12px;
    margin-bottom: 4px;

    font-weight: 700;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 0.32px;
    color: #525252;
}

.FiltersView__index-input {
    border: none;
    outline: none;

    width: 65%;
    padding-left: 8px;
    margin-left: 16px;
    border-radius: 10px;
    height: 32px;
    background-color: #f4f4f4;

    font-size: 14px;
    line-height: 18px;
    color: #161616;
}