.FeedbackView__root {
    width: 672px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    border-radius: 16px;
    overflow: hidden;
}

.FeedbackView__header-container {
    width: 672px;
    height: 64px;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
    position: relative;
}

.FeedbackView__header-title {
    margin-left: 32px;
    font-weight: 600;
    font-size: 20px;
    line-height: 26px;
    color: #161616;
}

.FeedbackView__body {
    width: 640px;
    padding-left: 32px;
    align-self: flex-end;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    background-color: #fff;
    margin-top: 4px;
    border-bottom-left-radius: 16px;
    border-bottom-right-radius: 16px;

    margin-bottom: 32px;
}

.FeedbackView__success-label {
    font-weight: 600;
    font-size: 20px;
    line-height: 26px;
    color: #161616;
    margin-bottom: 16px;
    margin-top: 16px;
}

.FeedbackView__success-text {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.32px;
    color: #525252;

    width: calc(100% - 32px);
}