.LogInModal__root {
    position: absolute;
    width: 100vw;
    height: 100vh;
    left: 0;
    top: 0;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    overflow-x: auto;
}

.LogInModal__background {
    position: fixed;
    z-index: 9;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background-color: rgba(141, 141, 141, 0.5);
    transition: all .3s;
}

.LogInModal__background-transparent {
    position: fixed;
    z-index: 9;
    width: 100vw;
    height: 100vh;
    left: 0;
    top: 0;
    background-color: rgba(141, 141, 141, 0.0);
    transition: all .3s;
}

/* height: 384px; */
.LogInModal__container {
    position: relative;
    margin-top: 128px;
    z-index: 10;
    width: 482px;
    background-color: #fff;
    border-radius: 16px;
    padding: 32px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    transition: all .31s;
}

.LogInModal__container-hidden {
    position: relative;
    margin-top: 108px;
    opacity: 0;
    z-index: 10;
    width: 482px;
    background-color: #fff;
    border-radius: 16px;
    padding: 32px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    transition: all .31s;
}

@media screen 
and (max-width: 800px) {
    .LogInModal__container, .LogInModal__container-hidden {
        width: 100%;
    }
}

.LogInModal__header {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 40px;
}

.LogInModal__header-left {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    cursor: default;
}

.LogInModal__label {
    font-size: 20px;
    font-weight: 400;
    line-height: 20px;
    color: #161616;
}

.LogInModal__switch-signup {
    margin-top: 10px;
    font-size: 14px;
    line-height: 22px;
    letter-spacing: 0.16px;
}

.LogInModal__switch-signup-link {
    color: #0F62FE;
    cursor: pointer;
    transition: filter .3s;
}

.LogInModal__switch-signup-link:hover {
    filter: opacity(0.7);
    transition: filter .3s;
}

.LogInModal__body {
    width: 100%
}

.LogInModal__options-container {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.LogInModal__options-checkbox-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;

    cursor: pointer;
}

.LogInModal__option-checkbox {
    width: 12px;
    height: 12px;
    border: 2px solid #EEEEEE;
    border-radius: 2px;
    margin-left: 4px;

    display: flex;
    align-items: center;
    justify-content: center;
}

.LogInModal__option-checkbox-active {
    width: 12px;
    height: 12px;
    border: 2px solid #525252;
    border-radius: 2px;
    margin-left: 4px;

    display: flex;
    align-items: center;
    justify-content: center;
}

.LogInModal__option-checkbox-inner {
    width: 8px;
    height: 8px;
    border-radius: 1px;
    background-color: #525252;
}

.LogInModal__option-checkbox-label {
    margin-left: 6px;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 0.32px;
    color: #161616;
}

.LogInModal__option-forget {
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    text-align: right;
    letter-spacing: 0.32px;
    color: #0F62FE;
    cursor: pointer;
    filter: opacity(1);
    transition: filter .2s;
}

.LogInModal__option-forget:hover {
    filter: opacity(0.7);
    transition: filter .2s;
}

.SignUpModal__root {
    position: absolute;
    width: 100%;
    height: 100vh;
    left: 0;
    top: 0;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    overflow-x: auto;
}

.SignUpModal__background {
    position: fixed;
    z-index: 9;
    width: 100%;
    height: 100vh;
    left: 0;
    top: 0;
    background-color: rgba(141, 141, 141, 0.5);
    transition: all .3s;
}

.SignUpModal__background-transparent {
    position: fixed;
    z-index: 9;
    width: 100%;
    height: 100vh;
    left: 0;
    top: 0;
    background-color: rgba(141, 141, 141, 0.0);
    transition: all .3s;
}

/* height: 384px; */
.SignUpModal__container {
    position: absolute;
    top: 128px;
    z-index: 10;
    width: 544px;
    max-width: 100%;
    background-color: #fff;
    border-radius: 16px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    transition: all .31s;
}

.SignUpModal__container-hidden {
    position: fixed;
    top: 108px;
    opacity: 0;
    z-index: 10;
    width: 544px;
    max-width: 100%;
    background-color: #fff;
    border-radius: 16px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    transition: all .31s;
}

@media screen 
and (max-width: 800px) {
    .SignUpModal__container, .SignUpModal__container-hidden {
        width: 100%;
    }
}

.SignUpModal__header {
    width: calc(100% - 62px);
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 40px;
    padding-left: 32px;
    padding-right: 32px;
    padding-top: 32px;
}

.SignUpModal__header-left {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    cursor: default;
}

.SignUpModal__label {
    font-size: 20px;
    font-weight: 400;
    line-height: 20px;
    color: #161616;
}

.SignUpModal__switch-signup {
    margin-top: 10px;
    font-size: 14px;
    line-height: 22px;
    letter-spacing: 0.16px;
}

.SignUpModal__switch-signup-link {
    color: #0F62FE;
    cursor: pointer;
    transition: filter .3s;
}

.SignUpModal__switch-signup-link:hover {
    filter: opacity(0.7);
    transition: filter .3s;
}

.SignUpModal__body {
    width: 100%;
    padding: 0px 32px 0px 32px;
}

.SignUpModal__body-legal-text {
    width: calc(100% - 62px);
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    text-align: center;
    margin-top: 32px;
}

.SignUpModal__body-buttons-container {
    width: calc(100% - 64px);
    padding-bottom: 32px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.SignUpModal__stage-header {
    height: 60px;
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
}

@media screen 
and (max-width: 800px) {
    .SignUpModal__stage-header {
        height: 46px;
    }
}

.SignUpModal__stage-item {
    width: 100%;
    height: 100%;
    min-width: max-content;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    border-bottom: 4px solid #0F62FE;
}

.SignUpModal__stage-item-inactive {
    width: 100%;
    height: 100%;
    min-width: max-content;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    border-bottom: 4px solid #C6C6C6;
}

.SignUpModal__stage-item-check {
    width: 14px;
    height: 14px;
    margin-top: 17.86px;
    margin-left: 17px;
    border-radius: 7px;
    background-color: #0F62FE;
    display: flex;
    justify-content: center;
    align-items: center;
}

.SignUpModal__stage-item-check-inactive {
    width: 12px;
    height: 12px;
    margin-top: 17.86px;
    margin-left: 17px;
    border-radius: 7px;
    border: 1px solid #C6C6C6;
    background-color: #0000;
    display: flex;
    justify-content: center;
    align-items: center;
}

.SignUpModal__stage-item-text-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    margin-top: 15px;
    margin-left: 9px;
}

.SignUpModal__stage-item-label {
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    margin: 0;
    color: #161616;
}

.SignUpModal__stage-item-desc {
    font-size: 12px;
    font-weight: 400;
    color: #525252;
    line-height: 16px;
}

.SignUpModal__stage-item-check-icon {
    width: 9px;
    object-fit: contain;
}

.SignUpModal__body-pwd-conf-header {
    padding: 32px 0px 8px 0px;
    font-size: 20px;
    font-weight: 600;
    line-height: 26px;
}

.SignUpModal__body-pwd-conf-text {
    width: calc(100% - 64px);
    color: #525252;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.32px;
}

.SignUpModal__body-pwd-conf-retry {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-end;
    margin-top: 28px;
    height: 22px;
}

.SignUpModal__body-pwd-conf-retry-ask {
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    letter-spacing: 0.16px;
}

.SignUpModal__body-pwd-conf-retry-action {
    margin-left: 8px;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 0.32px;
    color: #0F62FE;
    cursor: pointer;
    transition: color .3s;
}

.SignUpModal__body-pwd-conf-retry-action:hover {
    margin-left: 8px;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 0.32px;
    color: #5891FE;
    cursor: pointer;
    transition: color .3s;
}

.ProfileModal__container {
    position: fixed;
    width: 320px;
    height: 182px;
    border-radius: 16px;
    background-color: #fff;
    top: 80px;
    opacity: 1;
    right: 64px;
    z-index: 10;
    box-shadow: 0px 1px 5px rgba(150, 150, 150, 0.5);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    padding-top: 21px;
    padding-bottom: 21px;
    overflow: hidden;
    transition: all .3s;
}

.ProfileModal__container-hidden {
    position: fixed;
    width: 320px;
    height: 182px;
    border-radius: 16px;
    background-color: #fff;
    top: 74px;
    opacity: 0.0;
    right: 64px;
    z-index: 10;
    box-shadow: 0px 1px 5px rgba(150, 150, 150, 0.5);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    padding-top: 21px;
    padding-bottom: 21px;
    overflow: hidden;
    transition: all .3s;
}

.ProfileModal__item {
    margin-left: 16px;
    font-weight: 700;
    font-size: 16px;
    line-height: 22px;
    letter-spacing: 0.16px;
    color: #525252;
    cursor: pointer;
    filter: opacity(1);
    transition: filter .3s;
}

.ProfileModal__item-exit {
    margin-left: 16px;
    font-weight: 700;
    font-size: 16px;
    line-height: 22px;
    letter-spacing: 0.16px;
    color: #DA1E28;
    cursor: pointer;
    filter: opacity(1);
    transition: filter .3s;
}

.ProfileModal__item:hover {
    filter: opacity(0.7);
    transition: filter .3s;
}

.ProfileModal__item-exit:hover {
    filter: opacity(0.7);
    transition: filter .3s;
}

.ProfileModal__indicator {
    width: 8px;
    height: 22px;
    border-radius: 4px;
    background-color: #0F62FE;
    position: absolute;
    left: -4px;
    transition: top .3s;
}

.InfoModal__background {
    position: fixed;
    z-index: 9;
    width: 100vw;
    height: 100vh;
    left: 0;
    top: 0;
    background-color: rgba(141, 141, 141, 0.5);
    transition: all .3s;
}

.InfoModal__background-hidden {
    position: fixed;
    z-index: 9;
    width: 100vw;
    height: 100vh;
    left: 0;
    top: 0;
    background-color: rgba(141, 141, 141, 0.0);
    transition: all .3s;
}

/* height: 384px; */
.InfoModal__container {
    position: absolute;
    top: 156px;
    align-self: center;
    z-index: 10;
    width: 544px;
    background-color: #fff;
    border-radius: 16px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    transition: all .31s;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
}

.InfoModal__container-hidden {
    position: absolute;
    top: 136px;
    opacity: 0;
    z-index: 10;
    width: 544px;
    background-color: #fff;
    border-radius: 16px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    transition: all .31s;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
}

.InfoModal__header {
    margin-top: 32px;
    margin-left: 32px;
    font-weight: 600;
    font-size: 20px;
    line-height: 26px;
    color: #161616;
}

.InfoModal__input-container {
    width: calc(100% - 80px);
    margin-top: 22px;
    margin-left: 32px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}

.InfoModal__button-container {
    width: 480px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    margin-left: 32px;
    height: 32px;
    margin-bottom: 32px;
}

.InfoModal__progress-container {
    width: 544px;
    height: 64px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.InfoModal__progress-item {
    width: 272px;
    height: 60px;
    border-bottom: 4px solid #0F62FE;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
}

.InfoModal__progress-item-inactive {
    width: 272px;
    height: 60px;
    border-bottom: 4px solid #F4F4F4;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
}

.InfoModal__progress-circle {
    width: 14px;
    height: 14px;
    border-radius: 7px;
    background-color: #0F62FE;
    margin-left: 17px;
    margin-top: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.InfoModal__progress-circle-inactive {
    width: 12px;
    height: 12px;
    border-radius: 6px;
    background-color: #0000;
    border: 1px solid #C6C6C6;
    margin-left: 1px;
    margin-top: 18px;
}

.InfoModal__body {
    width: 544px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}

.InfoModal__progress-icon {
    width: 9px;
    object-fit: contain;
}

.InfoModal__progress-text-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    margin-top: 15px;
    margin-left: 9px;
}

.InfoModal__progress-title {
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 0.32px;
    color: #161616;
}

.InfoModal__progress-subtitle {
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.32px;
    color: #525252;
}

.InfoModal__subheader {
    margin-top: 8px;
    margin-left: 32px;
    width: 480px;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.32px;
    color: #525252;
}

.InfoModal__resend-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-end;
    height: 22px;
    margin-left: 32px;
    margin-bottom: 46px;
}

.InfoModal__resend-question {
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    letter-spacing: 0.16px;
    color: #161616;
    margin-right: 8px;
}

.InfoModal__resend-action {
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 0.32px; 
    color: #0F62FE;
    cursor: pointer;
    filter: opacity(1);
    transition: filter .3s;
}

.InfoModal__resend-action:hover {
    filter: opacity(0.7);
    transition: filter .3s;
}

/* COMPANY MODAL */

.CompanyModal__background {
    position: fixed;
    z-index: 9;
    width: 100vw;
    height: 100vh;
    left: 0;
    top: 0;
    background-color: rgba(141, 141, 141, 0.5);
    transition: all .3s;
}

.CompanyModal__background-hidden {
    position: fixed;
    z-index: 9;
    width: 100vw;
    height: 100vh;
    left: 0;
    top: 0;
    background-color: rgba(141, 141, 141, 0.0);
    transition: all .3s;
}

/* height: 384px; */
.CompanyModal__container {
    position: absolute;
    top: 206px;
    align-self: center;
    z-index: 10;
    width: 544px;
    background-color: #fff;
    border-radius: 16px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    transition: all .31s;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
}

.CompanyModal__container-hidden {
    position: absolute;
    top: 186px;
    opacity: 0;
    z-index: 10;
    width: 544px;
    background-color: #fff;
    border-radius: 16px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    transition: all .31s;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
}

.CompanyModal__search-container {
    width: 480px;
    min-height: 40px;
    border-radius: 16px;
    position: absolute;
    top: 272px;
    left: 32px;
    background-color: #fff;
    z-index: 10000;
    box-shadow: 0px 2px 4px 0px rgba(150, 150, 150, 0.25);
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}

.CompanyModal__search-results-wrapper {
    display: flex;
    flex-direction: column;
    width: 480px;
    justify-content: flex-start;
    align-items: flex-start;
}

.CompanyModal__search-item-container {
    width: 480px;
    height: 47px;
    border-bottom: 1px solid #E0E0E0;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    cursor: pointer;
}

.CompanyModal__search-item-title {
    margin-left: 16px;
    margin-top: 7px;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 0.16px;
    color: #525252;
}

.CompanyModal__search-item-director {
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.32px;
    color: #525252;
    margin-left: 16px;
}

.CompanyModal__action-container {
    width: 480px;
    height: 32px;
    margin-bottom: 32px;
    margin-left: 32px;
    margin-top: 32px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
}

.CompanyModal__input-label {
    font-size: 12px;
    line-height: 16px;
    color: #525252;
    margin-bottom: 8px;
    margin-left: 32px;
    font-family: lato, sans-serif;
    font-weight: 400;
    font-style: normal;
}

.InfoModal__root {
    position: fixed;
    left: 0;
    top: 0;
    z-index: 11;
    width: 100%;
    height: 100vh;
    overflow-x: auto;
    overflow-y: scroll;
}

/* PRODUCT MODAL */

.ProductModal__background {
    position: fixed;
    z-index: 9;
    width: 100%;
    height: 100vh;
    left: 0;
    top: 0;
    background-color: rgba(141, 141, 141, 0.5);
    transition: all .3s;
}

.ProductModal__background-hidden {
    position: fixed;
    z-index: 9;
    width: 100%;
    height: 100vh;
    left: 0;
    top: 0;
    background-color: rgba(141, 141, 141, 0.0);
    transition: all .3s;
}

/* height: 384px; */
.ProductModal__container {
    position: absolute;
    top: 156px;
    align-self: center;
    z-index: 10;
    width: 800px;
    background-color: #fff;
    border-radius: 16px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    transition: all .31s;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
}

.ProductModal__container-hidden {
    position: absolute;
    top: 136px;
    opacity: 0;
    z-index: 10;
    width: 800px;
    background-color: #fff;
    border-radius: 16px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    transition: all .31s;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
}

.ProductModal__progress-container {
    height: 60px;
    width: 800px;
    border-bottom: 4px solid #F4F4F4;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}

.ProductModal__progress-indicator {
    height: 14px;
    width: 14px;
    min-width: 14px;
    border-radius: 7px;
    background-color: #f4f4f4;
    margin-left: 9px;
    margin-right: 9px;
}

.ProductModal__progress-indicator-active {
    height: 14px;
    width: 14px;
    min-width: 14px;
    border-radius: 7px;
    background-color: #0F62FE;
    margin-left: 9px;
    margin-right: 9px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.ProductModal__body {
    width: 800px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}

.ProductModal__progress-title {
    margin-right: 10px;
    font-weight: 700;
    font-size: 14px;
    line-height: 18px;
    display: flex;
    align-items: center;
    letter-spacing: 0.32px;
    color: #161616;
}

.ProductModal__progress-stick {
    min-width: 112px;
    width: 100%;
    height: 4px;
    border-radius: 2px;
    background-color: #F4F4F4;
}

.ProductModal__body-title {
    margin: 32px;
    font-weight: 600;
    font-size: 20px;
    line-height: 26px;
    color: #161616;
}

.ProductModal__pickers-container-1 {
    width: 736px;
    height: 40px;
    margin-left: 32px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
}

.ProductModal__picker-container {
    width: 368px;
    height: 40px;
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.ProductModal__picker-input {
    border: none;
    width: 360px;
    height: 80px;
    font-weight: 700;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 0.32px;
    background-color: #fff;
}

.ProductModal__picker-result-container {
    position: absolute;
    left: 0;
    top: 48px;
    max-height: 321px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 368px;
    border-radius: 16px;
    background-color: #fff;
    z-index: 5;
    
    overflow-y: auto;
    overflow-x: hidden;

    box-shadow: 0px 2px 4px 0px rgba(100, 100, 100, 0.25);
}

.ProductModal__picker-result-wrapper {
    width: 364px;
    max-height: 320px;
    display: flex;
    flex-direction: column;
}

.ProductModal__picker-result-item {
    width: 100%;
    height: 40px;
    min-height: 40px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    position: relative;
    transition: background-color .2s;
    cursor: pointer;
}

.ProductModal__picker-result-item-label {
    margin-left: 16px;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 0.16px;
    color: #161616;
}

.ProductModal__picker-result-item-divider {
    width: 332px;
    height: 1px;
    position: absolute;
    left: 16px;
    top: 40px;
    background-color: #E0E0E0;
    transition: background-color .2s;
}

.ProductModal__picker-result-item:hover {
    background-color: #EDF5FF;
    transition: background-color .2s;
}

.ProductModal__picker-result-item:hover > .ProductModal__picker-result-item-divider {
    background-color: #E0E0E000;
    transition: background-color .2s;
}

.ProductModal__global-search-container {
    margin-top: 16px;
    margin-left: 32px;
    width: 736px;
    height: 32px;
    margin-bottom: 48px;
    position: relative;
}

.ProductModal__global-search-input {
    width: 720px;
    height: 32px;
    padding-left: 16px;
    border-radius: 16px;
    border: none;
    background-color: #F4F4F4;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 0.32px;
}

.ProductModal__global-result-container {
    position: absolute;
    left: 0;
    top: 40px;
    max-height: 320px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    overflow: hidden;
    width: 736px;
    border-radius: 16px;
    background-color: #fff;
    z-index: 5;
    overflow-x: hidden;
    overflow-y: auto;

    box-shadow: 0px 2px 4px 0px rgba(100, 100, 100, 0.25);
}

.ProductModal__global-result-wrapper {
    width: 732px;
    max-height: 320px;
    display: flex;
    flex-direction: column;
}

.ProductModal__global-result-item {
    width: 732px;
    height: 40px;
    min-height: 40px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    position: relative;
    cursor: pointer;
    background-color: #fff;
    transition: background-color .2s;
}

.ProductModal__global-result-item-label {
    margin-left: 16px;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 0.16px;
    color: #161616;
}

.ProductModal__global-result-item-divider {
    width: 704px;
    height: 1px;
    position: absolute;
    left: 16px;
    top: 40px;
    background-color: #E0E0E0;
    transition: background-color .2s;
}

.ProductModal__global-result-item:hover {
    background-color: #EDF5FF;
    transition: background-color .2s;
}

.ProductModal__global-result-item:hover > .ProductModal__global-result-item-divider {
    background-color: #E0E0E000;
    transition: background-color .2s;
}

.ProductModal__actions-container {
    width: 736px;
    margin-left: 32px;
    margin-bottom: 32px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
}

.ProductModal__inputs-container {
    width: 736px;
    margin-left: 32px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 12px;
}

.ProductModal__inputs-wrapper {
    display: grid;
    grid-template-columns: 1fr 1fr;
    width: 736px;
    margin-left: 32px;
}

.ProductModal__stock-selector-container {
    width: 736px;
    height: 40px;
    margin-left: 32px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    cursor: pointer;
    margin-bottom: 12px;
    position: relative;
}

.ProductModal__stock-selector-text {
    font-weight: 700;
    font-size: 14px;
    line-height: 18px;
    display: flex;
    align-items: center;
    letter-spacing: 0.32px;
    color: #A8A8A8;
    margin-right: 10px;
}

.ProductModal__stock-selector-chevron {
    transform: rotate(0);
    transition: transform .3s;
}

.ProductModal__stock-selector-chevron-open {
    transform: rotate(-180deg);
    transition: transform .3s;
}

.ProductModal__stock-list-container {
    width: 739px;
    position: absolute;
    left: 0;
    top: 35px;
    border-radius: 16px;
    overflow: hidden;
    background-color: #fff;
    box-shadow: 0px 3px 8px 0px rgba(0, 0, 0, 0.25);
    z-index: 5;
}

.ProductModal__stock-item-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    background-color: #EDF5FF00;
    transition: background-color .2s;
}

.ProductModal__stock-item-container:hover {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    background-color: #EDF5FFFF;
    transition: background-color .2s;
}

.ProductModal__stock-item-label {
    margin-top: 7px;
    margin-left: 16px;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 0.32px;
    color: #525252;
}

.ProductModal__stock-item-address {
    margin-bottom: 7px;
    margin-left: 16px;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.32px;
    color: #525252;
}

.ProductModal__stock-item-divider {
    height: 1px;
    width: 707px;
    margin-left: 16px;
    position: relative;
    top: 1px;
    background-color: #E0E0E0FF;
    z-index: -1;
    transition: background-color .2s;
}

.ProductModal__stock-item-container:hover > .ProductModal__stock-item-divider {
    height: 1px;
    width: 707px;
    margin-left: 16px;
    position: relative;
    top: 1px;
    background-color: #E0E0E000;
    z-index: -1;
    transition: background-color .2s;
}

.ProductModal__stock-button-container {
    width: 739px;
    height: 34px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    background-color: #EDF5FF00;
    transition: background-color .2s;
}

.ProductModal__stock-button-container:hover {
    width: 739px;
    height: 34px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    background-color: #EDF5FFFF;
    transition: background-color .2s;
}

.ProductModal__stock-button-label {
    margin-left: 16px;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.32px;
    color: #0F62FE;
}

.ProductModal__result-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    margin-left: 32px;
    width: 736px;
    position: relative;
}

.ProductModal__result-subheader {
    margin-top: 20px;
    font-weight: 700;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 0.32px;
    color: #161616;
}

.ProductModal__result-item-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    height: 18px;
    margin-top: 14px;
}

.ProductModal__result-item-label {
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.32px;
    color: #525252;
    margin-right: 8px;
}

.ProductModal__result-item-value {
    font-weight: 700;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 0.32px;
    color: #525252;
}

.ProductModal__result-divider {
    width: 768px;
    height: 4px;
    background-color: #F4F4F4;
    margin-top: 26px;
}

.ProductModal__result-item-edit {
    position: absolute;
    width: 16px;
    height: 16px;
    object-fit: contain;
    right: 0px;
    top: 20px;
    cursor: pointer;
    filter: opacity(1);
    transition: filter .3s;
}

.ProductModal__result-item-edit:hover {
    position: absolute;
    width: 16px;
    height: 16px;
    object-fit: contain;
    right: 0px;
    top: 20px;
    cursor: pointer;
    filter: opacity(0.7);
    transition: filter .3s;
}

.ProductModal__picker-input {
    margin-left: 16px;
}

.ProductModal__picker-label {
    font-size: 12px;
    line-height: 16px;
    color: #525252;
    margin-bottom: 8px;
    font-family: lato, sans-serif;
    font-weight: 400;
    font-style: normal;
}

.DeleteModal__container {
    position: absolute;
    top: 156px;
    align-self: center;
    z-index: 10;
    width: 544px;
    background-color: #fff;
    border-radius: 16px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    transition: all .31s;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
}

.DeleteModal__container-hidden {
    position: fixed;
    top: 136px;
    opacity: 0;
    z-index: 10;
    width: 544px;
    background-color: #fff;
    border-radius: 16px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    transition: all .31s;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
}

.DeleteModal__actions-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    height: 32px;
    width: calc(100% - 64px);
    margin: 32px;
}

.StockModal__container {
    position: absolute;
    top: 156px;
    z-index: 10;
    width: 544px;
    background-color: #fff;
    border-radius: 16px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    transition: all .31s;

    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
}

.StockModal__container-hidden {
    position: absolute;
    top: 136px;
    opacity: 0;
    z-index: 10;
    width: 544px;
    background-color: #fff;
    border-radius: 16px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    transition: all .31s;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
}

.StockModal__body {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 480px;
    margin-left: 32px;
    margin-top: 38px;
}

.StockModal__mode-switch {
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 0.32px;
    color: #0F62FE;
    cursor: pointer;
    filter: opacity(1);
    transition: filter .3s;
}

.StockModal__mode-switch:hover {
    filter: opacity(0.7);
    transition: filter .3s;
}

.StockModal__actions-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;
    margin-bottom: 32px;
    margin-top: 32px;
}

.StockModal__details-container {
    display: flex;
    flex-direction: row;
    width: 480px;
    justify-content: space-between;
    align-items: flex-start;
    margin-top: 4px;
}

.StockModal__full-address-wrapper {
    position: relative;
}

.StockModal__full-address-search-container {
    position: absolute;
    top: 72px;
    left: 0;
    width: 480px;
    border-radius: 16px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    background-color: #fff;
    z-index: 10;
    box-shadow: 0px 2px 4px 0px rgba(150, 150, 150, 0.25);
    overflow: hidden;
}

.StockModal__search-result-container {
    width: 480px;
    height: 48px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    cursor: pointer;
    background-color: #EDF5FF00;
    transition: background-color .2s;
}

.StockModal__search-result-container:hover {
    background-color: #EDF5FF;
    transition: background-color .2s;
}

.StockModal__search-result-label {
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 0.16px;
    color: #525252;
    margin-left: 16px;
}

.ProductRequestModal__container {
    position: absolute;
    top: 156px;
    align-self: center;
    z-index: 10;
    width: 800px;
    background-color: #fff;
    border-radius: 16px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    transition: all .31s;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    opacity: 1;
}

.ProductRequestModal__container-hidden {
    position: absolute;
    top: 136px;
    opacity: 0;
    z-index: 10;
    width: 800px;
    background-color: #fff;
    border-radius: 16px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    transition: all .31s;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
}

.ProductRequestModal__picker-wrapper {
    width: calc(100% - 64px);
    height: auto;
    align-self: center;

    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
}

.ProductRequestModal__picker-subtitle {
    width: calc(100% - 64px);
    align-self: center;

    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.32px;
    color: #525252;

    margin-bottom: 8px;
}

.ProductRequestModal__station-input-container {
    width: 100%;
    height: 40px;
    
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #E0E0E0;
    background-color: #FFF;

    position: relative;
}

.ProductRequestModal__station-input {
    border: none;
    outline: none;

    width: calc(100% - 16px);
    height: 30px;
    padding-left: 16px;

    font-weight: 700;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 0.32px;
    color: #161616;
}

.ProductRequestModal__station-input::placeholder { 
    color: #A8A8A8;
    opacity: 1;
}
  
.ProductRequestModal__station-input::-ms-input-placeholder { 
    color: #A8A8A8;
}
  
.ProductRequestModal__station-input::-ms-input-placeholder { 
    color: #A8A8A8;
}

.ProductRequestModal__country-results-container {
    width: 100%;
    height: auto;
    max-height: 200px;
    overflow: hidden;
    position: absolute;

    left: 0;
    top: 36px;
    box-shadow: 0px 3px 8px 0px rgba(100, 100, 100, 0.25);
    background-color: #fff;
    border-radius: 16px;
    z-index: 10;

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}

.ProductRequestModal__country-results-wrapper {
    width: 100%;
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}

.ProductRequestModal__country-result-item {
    width: 100%;
    height: 40px;
    min-height: 40px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    cursor: pointer;

    background-color: #EDF5FF00;
    transition: background-color .2s;

    position: relative;
}

.ProductRequestModal__country-result-item:hover {
    background-color: #EDF5FFFF;
    transition: background-color .2s;
}

.ProductRequestModal__country-result-item-label {
    margin-left: 16px;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 0.32px;
    color: #161616;
}

.ProductRequestModal__pricemode-picker-container {
    width: calc(100% - 64px);
    margin-left: 32px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr;
    grid-gap: 16px;

    align-items: center;
}

.ProductRequestModal__pricemode-picker-item {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: max-content;
    height: 40px;

    cursor: pointer;
}

.ProductRequestModal__pricemode-item-indicator {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    border: 2px solid #0F62FE;

    margin-left: 5px;

    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}

.ProductRequestModal__pricemode-item-indicator-inner {
    position: relative;

    width: 7px;
    height: 7px;
    border-radius: 4px;
    background-color: #0F62FE;
}

.ProductRequestModal__pricemode-item-label {
    font-weight: 700;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 0.32px;
    color: #161616;
    margin-left: 12px;
}

.ProductRequestModal__pricemode-label {
    margin-left: 32px;
    margin-bottom: 8px;

    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.32px;
    color: #525252;
}

.ProductRequestModal__price-container {
    width: calc(100% - 64px);
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr;
    grid-gap: 16px;

    margin-top: 24px;
}

.ProductRequestModal__price-input-container {
    width: 100%;
    margin-left: 32px;
}

.ProductRequestModal__price-input {
    border: none;
    outline: none;
    
    width: calc(100% - 16px);
    padding-left: 16px;
    height: 40px;
    border-radius: 16px;
    background-color: #F4F4F4;

    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 0.32px;
    color: #161616;
}

.ProductRequestModal__price-currency-picker-container {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-template-rows: 1fr;
    grid-gap: 10px;
    height: 40px;
}

.ProductRequestModal__price-currency-picker-item {
    width: max-content;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    cursor: pointer;
}

.SendOfferModal__title {
    margin-left: 32px;
    margin-top: 28px;

    font-weight: 600;
    font-size: 20px;
    line-height: 26px;
    color: #161616;
}

.SendOfferModal__button-container {
    width: calc(100% - 64px);
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 16px;
    margin: 22px 0px 32px 32px;
}

.TransactionModal__starter-info {
    width: 100%;
    background-color: #F4F4F4;
    margin-top: 11px;

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}

.CompanyNotProvided__root {
    position: fixed;
    left: 0;
    top: 0;
    z-index: 11;
    width: 100%;
    height: 100vh;
    overflow-x: auto;
    overflow-y: scroll;
}

.CompanyNotProvided__background {
    position: fixed;
    z-index: 9;
    width: 100vw;
    height: 100vh;
    left: 0;
    top: 0;
    background-color: rgba(141, 141, 141, 0.5);
    transition: all .3s;
}

.CompanyNotProvided__background-hidden {
    position: fixed;
    z-index: 9;
    width: 100vw;
    height: 100vh;
    left: 0;
    top: 0;
    background-color: rgba(141, 141, 141, 0.0);
    transition: all .3s;
}

/* height: 384px; */
.CompanyNotProvided__container {
    position: absolute;
    top: 156px;
    align-self: center;
    z-index: 10;
    width: 544px;
    background-color: #fff;
    border-radius: 16px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    transition: all .31s;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
}

.CompanyNotProvided__container-hidden {
    position: absolute;
    top: 136px;
    opacity: 0;
    z-index: 10;
    width: 544px;
    background-color: #fff;
    border-radius: 16px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    transition: all .31s;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
}

.CompanyNotProvided__content {
    font-size: 14px;
    line-height: 18px;
    font-weight: 400;
    color: #161616;
    letter-spacing: 0.16px;
    margin-left: 32px;
    margin-top: 20px;
}