.SliderPicker__container {
    height: 40px;
    border-radius: 16px;
    background-color: #F4F4F4;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.SliderPicker__item {
    position: relative;
    z-index: 1;
    font-size: 14px;
    line-height: 14px;
    width: 82.5px;
    text-align: center;
    margin: 8px;
    cursor: pointer;
}

.SliderPicker__indicator {
    position: absolute;
    margin: 8px;
    background-color: #FFFFFF;
    border-radius: 8px;
    width: 82px;
    height: 24px;
    z-index: 0;
    transition: all .3s;
}

/* LARGE */

.SliderPickerL__container {
    height: 40px;
    width: 480px;
    border-radius: 16px;
    background-color: #F4F4F4;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    position: relative;
}

.SliderPickerL__indicator {
    position: absolute;
    margin: 8px;
    background-color: #FFFFFF;
    border-radius: 8px;
    width: 162px;
    height: calc(100% - 16px);
    z-index: 0;
    transition: all .3s;
}

.SliderPickerL__item {
    position: relative;
    z-index: 1;
    font-size: 14px;
    line-height: 14px;
    width: 162px;
    text-align: center;
    margin: 8px;
    cursor: pointer;
}

.MultiPickerL__container {
    height: 40px;
    width: 480px;
    border-radius: 16px;
    background-color: #F4F4F4;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    position: relative;
}

.MultiPickerL__item {
    position: relative;
    z-index: 1;
    font-size: 14px;
    line-height: 14px;
    width: 100%;
    text-align: center;
}

.MultiPickerL__item-container {
    width: 100%;
    height: calc(100% - 16px);
    border-radius: 8px;
    background-color: #fff0;
    margin: 8px;
    position: relative;
    z-index: 1;
    cursor: pointer;

    display: flex;
    align-items: center;
    justify-content: center;
    
    transition: background-color .2s;
}

.MultiPickerL__item-container-active {
    width: 100%; /* 162px */
    height: calc(100% - 16px);
    border-radius: 8px;
    background-color: #ffff;
    margin: 8px;
    position: relative;
    z-index: 1;
    cursor: pointer;

    display: flex;
    align-items: center;
    justify-content: center;

    transition: background-color .2s;
}