body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

* {
  margin: 0;
}

*:focus {
  outline: none;
}

*::-webkit-scrollbar {
  width: 4px;
}

*::-webkit-scrollbar-track {
  background-color: #fff0;
}

*::-webkit-scrollbar-thumb {
  border-radius: 2px;
  background-color: #8D8D8D;
}