.RequestsView__root {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}

.RequestsView__header-container {
    width: 100%;
    margin-top: 61px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
}

.RequestsView__title-container {
    margin-left: 64px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}

.RequestsView__title {
    font-weight: 600;
    font-size: 32px;
    line-height: 40px;
    color: #161616;
}

.RequestsView__subtitle {
    margin-top: 7px;
    font-weight: 700;
    font-size: 16px;
    line-height: 22px;
    letter-spacing: 0.16px;
    color: #6F6F6F;
}

.RequestsView__action-container {
    margin-right: 64px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    height: 48px;
    width: 380px;
    cursor: pointer;
}

.RequestsView__action-filter {
    width: 188px;
    height: 48px;
    border-top-left-radius: 16px;
    border-bottom-left-radius: 16px;
    background-color: #fff;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    filter: opacity(1);
    transition: filter .3s;
}

.RequestsView__action-filter-label {
    margin-left: 16px;
    font-weight: 700;
    font-size: 16px;
    line-height: 22px;
    letter-spacing: 0.16px;
    color: #C6C6C6;
    filter: opacity(1);
    transition: filter .3s;
}

.RequestsView__action-filter:hover {
    filter: opacity(0.7);
    transition: filter .3s;
}

.RequestsView__action-filter-icon {
    margin-right: 20px;
}

.RequestsView__action-add {
    width: 188px;
    height: 48px;
    border-top-right-radius: 16px;
    border-bottom-right-radius: 16px;
    background-color: #fff;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-left: 4px;   
}

.RequestsView__action-add:hover {
    filter: opacity(0.7);
    transition: filter .3s;
}

.RequestsView__action-add-label {
    margin-left: 16px;
    font-weight: 700;
    font-size: 16px;
    line-height: 22px;
    letter-spacing: 0.16px;
    color: #0F62FE;
}

.RequestsView__action-add-icon {
    margin-right: 24px;
    font-weight: 400;
    font-size: 24px;
    line-height: 24px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #0F62FE;
}

.RequestsView__nothing-container {
    width: 352px;
    height: 192px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 16px;
    background-color: #fff;
    margin-top: 100px;
    position: relative;
}

.RequestsView__nothing-header {
    margin-bottom: 6px;
    font-weight: 600;
    font-size: 20px;
    line-height: 26px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #161616;
}

.RequestsView__nothing-text {
    margin-bottom: 20px;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 0.32px;
    color: #525252;
    width: 100%;
    height: auto;
    text-align: center;
}

.RequestsView__filter-root {
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: flex-start;
    align-items: center;
}

.RequestsView__search-container {
    width: 100%;
    height: 64px;
    margin-top: 30px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
}

.RequestsView__search-catogory-container {
    margin-left: 64px;
    margin-right: 4px;
    width: 22vw;
    height: 64px;
    border-top-left-radius: 16px;
    border-bottom-left-radius: 16px;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
}

.RequestsView__search-naming-container {
    margin-right: 4px;
    width: 22vw;
    height: 64px;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
}

.RequestsView__search-value-container {
    margin-right: 64px;
    width: 50%;
    height: 64px;
    border-top-right-radius: 16px;
    border-bottom-right-radius: 16px;
    background-color: #fff;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.RequestsView__search-item-label {
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.32px;
    color: #525252;
    margin-left: 16px;
    margin-bottom: 1px;
}

.RequestsView__search-item-value-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin-left: 16px;
}

.RequestsView__search-item-value {
    font-weight: 700;
    font-size: 16px;
    line-height: 22px;
    letter-spacing: 0.16px;
    color: #161616;
}

.RequestsView__search-input {
    border: none;
    margin-left: 16px;
    width: 100%;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 0.32px;
}

.RequestsView__filter-container {
    margin-top: 16px;
    width: calc(100% - 128px);
    margin-left: 64px;
    margin-right: 64px;
    height: 48px;
    border-radius: 16px;
    background-color: #fff;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.RequestsView__filter-container-title {
    margin-left: 16px;
    font-weight: 700;
    font-size: 16px;
    line-height: 22px;
    display: flex;
    align-items: center;
    letter-spacing: 0.16px;
    color: #525252;
}

.RequestsView__filter-actions-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin-right: 16px;

}

.RequestsView__filter-actions-item-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    filter: opacity(1);
    transition: filter .3s;
}

.RequestsView__filter-actions-item-container:hover {
    filter: opacity(0.7);
    transition: filter .3s;
}

.RequestsView__filter-actions-item-label {
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.32px;
    color: #525252;
    margin-right: 4px;
}

.RequestsView__filter-actions-chevron {
    transform: rotate(0deg);
    transition: transform .3s;
}

.RequestsView__filter-actions-chevron-turned {
    transform: rotate(-180deg);
    transition: transform .3s;
}

.RequestsView__filter-details-container {
    width: calc(100% - 144px);
    margin-top: 8px;
    margin-bottom: 8px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr;
}

.RequestsView__filter-details-item-container {
    margin: 8px;
    background-color: #fff;
    border-radius: 16px;
    height: 80px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}

.RequestsView__filter-details-item-label {
    margin-left: 16px;
    margin-top: 12px;
    margin-bottom: 8px;
    font-weight: 700;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 0.32px;
    color: #525252;
}

.RequestsView__filter-details-item-element {
    
}

.RequestsView__counter {
    font-weight: 700;
    font-size: 16px;
    line-height: 22px;
    letter-spacing: 0.16px;
    color: #6F6F6F;
}




.RequestsView__products-wrapper {
    width: calc(100% - 96px);
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
    margin-top: 18px;
    
    position: relative;
}

.RequestsView__products-table {
    width: calc(100% - 128px);
    min-width: 1024px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    border-radius: 16px;
    overflow: hidden;
    margin-bottom: 10px;
    position: relative;
}

.RequestsView__products-table-header {
    height: 48px;
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 2fr;
    background-color: #fff;
    margin-bottom: 4px;
}

.RequestsView__products-table-column-label {
    font-weight: 700;
    font-size: 14px;
    line-height: 18px;
    display: flex;
    align-items: center;
    letter-spacing: 0.32px;
    color: #161616;
}

.RequestsView__product-line {
    width: 100%;
    height: 44px;
    background-color: #fff;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 2fr;
    transition: background-color .2s, height .2s;
    align-items: flex-start;
    cursor: pointer;
}

.RequestsView__product-line-opened {
    width: 100%;
    height: 111px;
    background-color: #fff;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 2fr;
    transition: background-color .2s, height .2s;
    align-items: flex-start;
    cursor: default;
}

.RequestsView__product-line:hover {
    background-color: #EDF5FF;
    transition: background-color .2s, height .2s;
}

.RequestsView__product-volume {
    margin-top: 11px;
    margin-left: 32px;
    font-weight: 700;
    font-size: 16px;
    line-height: 22px;
    letter-spacing: 0.16px;
    color: #0F62FE;
}

.RequestsView__product-value-wrapper {
    margin-top: 13px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}

.RequestsView__product-value {
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 0.32px;
    color: #161616;
}

.RequestsView__product-value-filter-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin-top: 24px;
}

.RequestsView__product-value-filter-label {
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.32px;
    color: #161616;
    margin-right: 8px;
}

.RequestsView__product-value-filter-value {
    font-weight: 700;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 0.32px;
    color: #161616;
}

.RequestsView__product-line-actions-wrapper {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    margin-top: 2px;
}

.RequestsView__product-line-chevron {
    margin-right: 16px;
    cursor: pointer;
    transform: rotate(0deg);
    transition: transform .3s;
    margin-left: 8px;
}

.RequestsView__product-line-chevron-rotated {
    margin-right: 16px;
    margin-left: 8px;
    cursor: pointer;
    transform: rotate(-180deg);
    transition: transform .3s;
}

.RequestsView__product-line-action-icon {
    width: 16px;
    height: 16px;
    object-fit: contain;
    filter: opacity(0);
    transition: filter .2s;
}

.RequestsView__product-line:hover > .RequestsView__product-line-actions-wrapper > .RequestsView__product-line-action-icon-container > .RequestsView__product-line-action-icon {
    filter: opacity(1);
    transition: filter .2s;
}

.RequestsView__product-line-opened:hover > .RequestsView__product-line-actions-wrapper > .RequestsView__product-line-action-icon-container > .RequestsView__product-line-action-icon {
    filter: opacity(1);
    transition: filter .2s;
}

.RequestsView__product-line-action-icon-container {
    width: 40px;
    height: 40px;
    border-radius: 20px;
    background-color: #0353E900;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: background-color .2s;
    cursor: pointer;
}

.RequestsView__product-line-action-icon-container:hover {
    background-color: #0353E90A;
    transition: background-color .2s;
}

.RequestsView__product-line-action-icon:hover {
    filter: opacity(0.7);
    transition: filter .2s;
}

.ProdRequestsView__products-table-header {
    height: 48px;
    width: 100%;
    margin-bottom: 4px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 2fr;
    background-color: #fff;
}

.ProdRequestsView__product-line {
    width: 100%;
    height: 44px;
    background-color: #fff;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 2fr;
    transition: background-color .2s, height .2s;
    align-items: flex-start;
    cursor: pointer;

    overflow: hidden;
}

.ProdRequestsView__product-line-opened {
    width: 100%;
    height: 111px;
    background-color: #fff;
    display: grid; 
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 2fr;
    transition: background-color .2s, height .2s;
    align-items: flex-start;
    cursor: default;
    
    overflow: hidden;
}

.ProdRequestsView__product-line:hover {
    background-color: #EDF5FF;
    transition: background-color .2s, height .2s;
}

.ProdRequestsView__product-line:hover > .RequestsView__product-line-actions-wrapper > .RequestsView__product-line-action-icon-container > .RequestsView__product-line-action-icon {
    filter: opacity(1);
    transition: filter .2s;
}

.ProdRequestsView__product-line-opened:hover > .RequestsView__product-line-actions-wrapper > .RequestsView__product-line-action-icon-container > .RequestsView__product-line-action-icon {
    filter: opacity(1);
    transition: filter .2s;
}

.RequestsView__product-line-divider {
    width: calc(100% - 32px);
    height: 4px;
    background-color: #F4F4F4;

    margin-left: 32px;
}

.RequestsView__wrapper {
    width: 100%;

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    background-color: #fff;
}

.ProdRequestsView__wrapper {
    width: 100%;

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    background-color: #fff;
}