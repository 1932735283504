.InfoField__container {
    width: 100%;
    height: 60px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    position: relative;
    border-bottom: 4px solid #f4f4f4;
}

.InfoField__label {
    margin-top: 11px;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.32px;
    color: #525252;
}

.InfoField__value {
    margin-top: 4px;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 0.32px;
    color: #161616;
    width: 512px;
}

.InfoField__edit-icon {
    width: 16px;
    object-fit: contain;
    position: absolute;
    top: 18px;
    right: 32px;
    cursor: pointer;
}

.StoreField__container {
    width: 100%;
    height: 92px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    position: relative;
    border-bottom: 4px solid #f4f4f4;
}

.StoreField__delete-icon {
    width: 14px;
    object-fit: contain;
    position: absolute;
    top: 18px;
    right: 75px;
    cursor: pointer;
}

.DropdownPicker__container {
    cursor: default;
    width: 100%;
    height: 84px;
}

.DropdownPicker__label {
    font-size: 12px;
    line-height: 16px;
    color: #525252;
    margin-bottom: 8px;
    font-family: lato, sans-serif;
    font-weight: 400;
    font-style: normal;
}

.DropdownPicker__wrapper {
    width: 480px;
    height: 40px;
    border-radius: 20px;
    background-color: #F4F4F4;
    color: #161616;
    border-width: 0;
    transition: background-color .3s;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    position: relative;
}

.DropdownPicker__value-default {
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 0.32px;
    color: #A8A8A8;
    margin-left: 16px;
}

.DropdownPicker__value {
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 0.32px;
    color: #161616;
    margin-left: 16px;
}

.DropdownPicker__chevron {
    margin-right: 16px;
    transform: rotate(0deg);
    transition: transform .3s;
}

.DropdownPicker__chevron-rotated {
    margin-right: 16px;
    transform: rotate(-180deg);
    transition: transform .3s;
}

.DropdownPicker__list-container {
    display: flex;
    flex-direction: column;
    width: 480px;
    justify-content: flex-start;
    align-items: flex-start;
    box-shadow: 0px 3px 8px 0px rgba(100, 100, 100, 0.25);
    overflow: hidden;
    border-radius: 16px;
    position: absolute;
    left: 0;
    top: 48px;
    z-index: 10;
    background-color: #fff;
}

.DropdownPicker__list-item-container {
    height: 48px;
    width: 480px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    background-color: #EDF5FF00;
    transition: background-color .2s;
}

.DropdownPicker__item-divider {
    width: 448px;
    height: 1px;
    background-color: #E0E0E0;
    position: absolute;
    left: 16px;
    top: 48px;
    z-index: -1;
    transition: background-color .2s;
}

.DropdownPicker__list-item-container:hover {
    background-color: #EDF5FF;
    transition: background-color .2s;
}

.DropdownPicker__list-item-container:hover > .DropdownPicker__item-divider {
    background-color: #e0e0e000;
    transition: background-color .2s;
}

.DropdownPicker__item-label {
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 0.16px;
    color: #525252;
    margin-left: 16px;
}

.SquarePicker__container {
    width: 100%;
    height: 40px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #E0E0E0;
    background-color: #FFF;
    cursor: pointer;

    position: relative;
    transition: background-color .2s, border-bottom .2s;
}

.SquarePicker__container-error {
    width: 100%;
    height: 40px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #DA1E28;
    background-color: #FFF1F1;
    cursor: pointer;

    position: relative;
    transition: background-color .2s, border-bottom .2s;
}

.SquarePicker__placeholder {
    font-weight: 700;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 0.32px;
    color: #A8A8A8;

    margin-left: 16px;
}

.SquarePicker__value {
    font-weight: 700;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 0.32px;
    color: #161616;

    margin-left: 16px;
}

.SquarePicker__chevron {
    transform: rotate(0deg);
    margin-right: 16px;

    transition: transform .2s;
}

.SquarePicker__chevron-rotated {
    transform: rotate(-180deg);
    margin-right: 16px;

    transition: transform .2s;
}

.SquarePicker__list-container {
    width: inherit;
    height: auto;
    max-height: 200px;
    overflow: hidden;
    position: absolute;

    left: 0;
    top: 36px;
    box-shadow: 0px 3px 8px 0px rgba(100, 100, 100, 0.25);
    background-color: #fff;
    border-radius: 16px;
    z-index: 10;

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}

.SquarePicker__item-wrapper {
    width: 100%;
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}

.SquarePicker__list-item-container {
    width: 100%;
    height: 40px;
    min-height: 40px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;

    background-color: #EDF5FF00;
    transition: background-color .2s;
    cursor: pointer;

    position: relative;
}

.SquarePicker__list-item-container:hover {
    background-color: #EDF5FFFF;
    transition: background-color .2s;
}

.SquarePicker__item-label {
    margin-left: 16px;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 0.32px;
    color: #161616;
}

.SquarePicker__item-divider {
    position: absolute;
    bottom: -1px;
    left: 16px;

    height: 1px;
    width: calc(100% - 32px);
    background-color: #E0E0E0FF;

    transition: background-color .2s;
}

.SquarePicker__list-item-container:hover > .SquarePicker__item-divider {
    background-color: #E0E0E000;
    transition: background-color .2s;
}