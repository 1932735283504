.PersonalView__root {
    width: 672px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    border-radius: 16px;
    overflow: hidden;
}

.PersonalView__header-container {
    width: 672px;
    height: 64px;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
}

.PersonalView__header-title {
    margin-left: 32px;
    font-weight: 600;
    font-size: 20px;
    line-height: 26px;
    color: #161616;
}

.PersonalView__body {
    width: 640px;
    padding-left: 32px;
    align-self: flex-end;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    background-color: #fff;
    margin-top: 4px;
}