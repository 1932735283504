.NavBar__container {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 96px;
    z-index: 8;
    background-color: white;
    border-bottom-left-radius: 16px;
    border-bottom-right-radius: 16px;
    box-shadow: 0px 0px 16px 0px rgba(155, 155, 155, 0.2);
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
}

.NavBar__logo-link {
    position: absolute;
    left: 64px;
}

.NavBar__logo {
    width: 120px;
    object-fit: contain;
    cursor: pointer;
}

.NavBar__location-content {
    position: absolute;
    left: 200px;
    font-weight: 600;
    font-size: 18px;
    line-height: 26px;
    color: #161616;
}

.NavBar__user-name {
    font-weight: 700;
    font-size: 16px;
    line-height: 22px;
    letter-spacing: 0.16px;
    margin-left: 8px;
    margin-right: 8px;
}

.NavBar__chevron {
    transform: rotate(90deg);
    margin-right: 64px;
}

.NavBar__user-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    filter: opacity(1);
    transition: filter .3s;
}

.NavBar__user-container:hover {
    filter:opacity(0.7);
    transition: filter .3s;
}

.NavBar__lang-selector {
    position: relative;
    display: flex;
    flex-direction: row;
    cursor: default;
    text-decoration: underline;
    color: #0F62FE;
    margin-left: auto;
    margin-right: 16px;
    line-height: 14px;
    margin-bottom: 3px;
}