.App__root {
    width: 100%;
    padding-top: 96px;
    font-family: 'Lato', sans-serif;
}

@font-face {
    font-family: 'Lato';
    font-weight: 400;
    src: local('Lato'), url(./fonts/latoregular.woff) format('woff');
}
  
@font-face {
    font-family: 'Lato';
    font-weight: 600;
    src: local('Lato'), url(./fonts/latosemibold.woff) format('woff');
}
  
@font-face {
    font-family: 'Lato';
    font-weight: 700;
    src: local('Lato'), url(./fonts/latobold.woff) format('woff');
}

input {
    font-family: 'Lato';
}