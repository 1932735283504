.AccountPage__root {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}

.AccountPage__greeting {
    font-weight: 600;
    font-size: 32px;
    line-height: 40px;
    color: #161616;
    padding-left: 64px;
    margin-top: 61px;
}

.AccountPage__content-container {
    width: 1024px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    margin-top: 27px;
    margin-left: 64px;
}

.AccoutPage__content-left {
    width: 320px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}

.AccoutPage__menu-container {
    position: relative;
    width: 320px;
    height: 86px;
    padding-top: 21px;
    padding-bottom: 21px;
    border-radius: 16px;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    overflow: hidden;
}

.AccountPage__menu-item {
    margin-left: 16px;
    font-weight: 700;
    font-size: 16px;
    line-height: 22px;
    letter-spacing: 0.16px;
    color: #161616;
    cursor: pointer;
    filter: opacity(1);
    transition: filter .3s;
}

.AccountPage__menu-item:hover {
    filter: opacity(0.7);
    transition: filter .3s;
}

.AccountPage__menu-indicator {
    width: 8px;
    height: 22px;
    border-radius: 4px;
    background-color: #0F62FE;
    position: absolute;
    left: -4px;
    transition: top .3s;
}

.AccountPage__back-to-market {
    width: 320px;
    height: 64px;
    border-radius: 16px;
    background-color: #fff;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin-top: 32px;
    cursor: pointer;
}

.AccountPage__back-to-market-icon {
    margin-left: 32px;
    transition: filter .3s;
}

.AccountPage__back-to-market-text {
    margin-left: 16px;
    font-weight: 700;
    font-size: 16px;
    line-height: 22px;
    letter-spacing: 0.16px;
    color: #161616;
    transition: filter .3s;
}

.AccountPage__back-to-market:hover > .AccountPage__back-to-market-text {
    filter: opacity(0.7);
    transition: filter .3s;
}

.AccountPage__back-to-market:hover > .AccountPage__back-to-market-icon {
    filter: opacity(0.7);
    transition: filter .3s;
}

.AccountPage__content-right {
    width: 672px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    margin-left: 32px;
}

.AccountPage__verification-container {
    width: 672px;
    height: 64px;
    border-radius: 16px;
    background-color: #fff;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    position: relative;
    margin-bottom: 32px;
}

.AccountPage__verification-warning-icon {
    width: 36px;
    object-fit: contain;
    margin-left: 16px;
}

.AccountPage__verification-title {
    font-weight: 700;
    font-size: 14px;
    line-height: 18px;
    color: #161616;
    letter-spacing: 0.32px;
    margin-left: 8px;
}

.AccountPage__verification-info-icon {
    width: 20px;
    object-fit: contain;
    margin-left: 8px;
    cursor: pointer;
}

.AccountPage__outlet {
    width: 672px;
    border-radius: 16px;
}