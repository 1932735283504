.ProdShow__container {
    width: calc(100% - 128px);
    min-width: 1096px;

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;

    border-radius: 16px;
    overflow: hidden;

    margin-left: 64px;
}

.ProdShow__wrapper {
    width: 100%;

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    background-color: #fff;
}

.ProdShow__table-header-container {
    width: 100%;
    height: 48px;
    background-color: #fff;

    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 2fr;

    align-items: center;
    margin-bottom: 4px;
}

.ProdShow__table-label {
    font-weight: 700;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 0.32px;
    color: #161616;
}

.ProdShow__tile-container {
    width: 100%;
    height: 44px;
    background-color: #fff;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 2fr;
    transition: background-color .2s, height .2s;
    align-items: flex-start;
    position: relative;
    cursor: pointer;
}

.ProdShow__tile-container-opened {
    width: 100%;
    height: 111px;
    background-color: #fff;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 2fr;
    transition: background-color .2s, height .2s;
    align-items: flex-start;
    justify-content: flex-start;
    position: relative;
    cursor: default;
}

.ProdShow__value-volume {
    margin-top: 11px;
    font-weight: 700;
    font-size: 16px;
    line-height: 22px;
    letter-spacing: 0.16px;
    color: #0F62FE;
}

.ProdShow__value-region {
    font-weight: 700;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 0.32px;
    color: #161616;
}

.ProdShow__value {
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 0.32px;
    color: #161616;
}

.ProdShow__tile-divider {
    width: calc(100% - 32px);
    height: 4px;
    background-color: #F4F4F4;

    margin-left: 32px;
}

.ProdShow__action-container {
    position: absolute;
    right: 0;
    top: 16px;

    filter: opacity(0);
    transition: filter .2s;

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.ProdShow__action-button {
    font-weight: 700;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 0.32px;
    color: #0F62FE;
    margin-right: 4px;

    cursor: pointer;
    filter: opacity(1);
    transition: filter .2s;
}

.ProdShow__action-button:hover {
    filter: opacity(0.7);
    transition: filter .2s;
}

.ProdShow__tile-container:hover {
    background-color: #EDF5FF;
    transition: background-color .2s, height .2s;
}

.ProdShow__tile-container:hover > .ProdShow__action-container {
    filter: opacity(1);
    transition: filter .2s;
}

.ProdShow__tile-container-opened:hover > .ProdShow__action-container {
    filter: opacity(1);
    transition: filter .2s;
}

.ProdShow__tile-chevron {
    margin-right: 16px;
    cursor: pointer;
    transform: rotate(0deg);
    transition: transform .3s;
    margin-left: 8px;
}

.ProdShow__tile-chevron-rotated {
    margin-right: 16px;
    margin-left: 8px;
    cursor: pointer;
    transform: rotate(-180deg);
    transition: transform .3s;
}

.ProdShow__product-value-wrapper {
    margin-top: 13px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}

.ProdShow__product-value-filter-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin-top: 24px;
}

.ProdShow__product-value-filter-label {
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.32px;
    color: #161616;
    margin-right: 8px;
}

.ProdShow__product-value-filter-value {
    font-weight: 700;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 0.32px;
    color: #161616;
}

.ProdReqShow__table-header-container {
    width: 100%;
    height: 48px;
    background-color: #fff;

    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 2fr;

    align-items: center;
    margin-bottom: 4px;
}

.ProdReqShow__tile-container {
    width: 100%;
    height: 44px;
    background-color: #fff;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 2fr;
    transition: background-color .2s, height .2s;
    align-items: flex-start;
    position: relative;
    cursor: pointer;
}

.ProdReqShow__tile-container-opened {
    width: 100%;
    height: 111px;
    background-color: #fff;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 2fr;
    transition: background-color .2s, height .2s;
    align-items: flex-start;
    justify-content: flex-start;
    position: relative;
    cursor: default;
}

.ProdReqShow__tile-container:hover {
    background-color: #EDF5FF;
    transition: background-color .2s, height .2s;
}

.ProdReqShow__tile-container:hover > .ProdShow__action-container {
    filter: opacity(1);
    transition: filter .2s;
}

.ProdReqShow__tile-container-opened:hover > .ProdShow__action-container {
    filter: opacity(1);
    transition: filter .2s;
}