.TextField__container {
    cursor: default;
    width: 100%;
    height: 84px;
    position: relative;
}

.TextField__label {
    font-size: 12px;
    line-height: 16px;
    color: #525252;
    margin-bottom: 8px;
    font-family: lato, sans-serif;
    font-weight: 400;
    font-style: normal;
}

.TextField__input {
    width: 100%;
    height: 40px;
    border-radius: 20px;
    background-color: #F4F4F4;
    color: #161616;
    border-width: 0;
    font-size: 14px;
    line-height: 18px;
    padding-left: 16px;
    font-weight: 400;
    letter-spacing: 0.32px;
    transition: background-color .3s;
}

.TextField__error-message {
    font-size: 12px;
    line-height: 16px;
    color: #DA1E28;
    font-family: lato, sans-serif;
    font-weight: 400;
    font-style: normal;
    margin-top: 4px;
    opacity: 1;
    transition: all .3s;
}

.TextField__error-message-hidden {
    font-size: 12px;
    line-height: 16px;
    color: #DA1E28;
    font-family: lato, sans-serif;
    font-weight: 400;
    font-style: normal;
    margin-top: 0px;
    opacity: 0;
    transition: all .3s;
}

.TextField__error-icon {
    position: absolute;
    right: 44px;
    padding-top: 9px;
    opacity: 1;
    transition: opacity .3s;
}

.TextField__error-icon-hidden {
    position: absolute;
    right: 44px;
    padding-top: 9px;
    opacity: 0;
    transition: opacity .3s;
}

.SecureTextField__container {
    cursor: default;
    
}

.SecureTextField__label {
    font-size: 12px;
    line-height: 16px;
    color: #525252;
    margin-bottom: 8px;
    font-family: lato, sans-serif;
    font-weight: 400;
    font-style: normal;
}

.SecureTextField__input-container {
    width: 100%;
    height: 42px;
    min-height: 42px;
    max-height: 42px;
    border-radius: 20px;
    background-color: #F4F4F4;
    border-width: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    font-family: 'Lato', sans-serif;
    font-weight: 400;
    font-style: normal;
    transition: background-color .3s;
}

.SecureTextField__input-container-error {
    width: 100%;
    height: 42px;
    min-height: 42px;
    max-height: 42px;
    border-radius: 20px;
    background-color: #FFF1F1;
    border-width: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    font-family: 'Lato', sans-serif;
    font-weight: 400;
    font-style: normal;
    transition: background-color .3s;
}

.SecureTextField__input {
    width: 100%;
    outline: none;
    height: 40px;
    max-height: 40px;
    min-height: 40px;
    border-radius: 20px;
    background-color: #F4F4F4;
    color: #161616;
    border-width: 0;
    line-height: 18px;
    padding-left: 16px;
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 0.32px;
}

.SecureTextField__eye {
    width: 24px;
    height: 24px;
    object-fit: contain;
    margin-right: 12px;
    cursor: pointer;
}

.NumberField__container {
    cursor: default;
}

.NumberField__label {
    font-size: 12px;
    line-height: 16px;
    color: #525252;
    margin-bottom: 8px;
    font-family: lato, sans-serif;
    font-weight: 400;
    font-style: normal;
}

.NumberField__input-container {
    width: 480px;
    height: 40px;
    border-radius: 20px;
    background-color: #F4F4F4;
    border-width: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    font-family: 'Lato', sans-serif;
    font-weight: 400;
    font-style: normal;
    transition: background-color .3s;
}

.NumberField__input-container-error {
    width: 480px;
    height: 40px;
    border-radius: 20px;
    background-color: #FFF1F1;
    border-width: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    font-family: 'Lato', sans-serif;
    font-weight: 400;
    font-style: normal;
    transition: background-color .3s;
}

.NumberField__code-button {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-left: 14px;
    cursor: pointer;
}

.NumberField__code-value {
    font-size: 14px;
    margin-right: 5px;
}

.NumberField__code-button-chevron {
    transform: rotate(0deg);
    transition: all .3s;
}

.NumberField__code-button-chevron-rotate {
    transform: rotate(180deg);
    transition: all .3s;
}

.NumberField__input {
    width: 100%;
    margin-left: 16px;
    height: 40px;
    border-radius: 20px;
    background-color: #F4F4F4;
    color: #161616;
    border-width: 0;
    line-height: 18px;
    font-size: 14px;
}

.NumberField__code-selector {
    width: 140px;
    height: 120px;
    background-color: #fff;
    border-radius: 16px;
    position: absolute;
    margin-top: 165px;
    box-shadow: 0px 0px 13px 5px rgba(156, 156, 156, 0.2);
    transition: all .4s;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    overflow: hidden;
    z-index: 2;
}

.NumberField__code-selector-hidden {
    width: 140px;
    height: 110px;
    background-color: #fff;
    border-radius: 16px;
    position: absolute;
    margin-top: 155px;
    opacity: 0;
    box-shadow: 0px 0px 13px 5px rgba(156, 156, 156, 0.2);
    transition: all .4s;
    display: flex;
    flex-direction: column;
    overflow: hidden;
}

.NumberField__code-selector-item {
    background-color: #fff;
    height: 30px;
    padding: 0 12px 0 12px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    cursor: pointer;
    transition: all .3s;
}

.NumberField__code-selector-item:hover {
    background-color: #d6d6d6;
    height: 30px;
    padding: 0 12px 0 12px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    cursor: pointer;
    transition: all .3s;
}

.NumberField__code-selector-item:hover > .NumberField__code-selector-divider {
    opacity: 0;
    transition: all .3s;
}

.NumberField__code-selector-item-label {
    font-size: 14px;
    line-height: 17px;
    cursor: pointer;
}

.NumberField__code-selector-divider {
    position: absolute;
    margin-top: 31px;
    width: 116px;
    height: 1px;
    background-color: #d6d6d6;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.NumberField__error-icon {
    position: absolute;
    right: 44px;
    opacity: 1;
    transition: opacity .3s;
}

.NumberField__error-icon-hidden {
    position: absolute;
    right: 44px;
    opacity: 0;
    transition: opacity .3s;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.MultilineTextField__container {
    cursor: default;
    width: 100%;
    height: auto;
}

.MultilineTextField__label {
    margin-top: 11px;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.32px;
    color: #525252;
}

.MultilineTextField__input {
    border-radius: 16px;
    width: calc(100% - 48px);
    max-width: calc(100% - 48px);
    min-width: calc(100% - 48px);
    height: 29px;
    min-height: 29px;
    margin-top: 8px;
    border: none;
    outline: none;
    padding-left: 16px;
    padding-top: 11px;
    background-color: #F4F4F4;

    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 0.32px;
    color: #161616;
}